import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(
    private datePipe: DatePipe
  ) { }



  /**
   * Transforms a Date got from Requets GET API to a NgbDateStruct object
   * @param dateFromAPI, i.e.: "2023-01-03"
   * @returns
   */
  transformSimpleDateToNgbDateStruct(dateFromAPI: any): NgbDateStruct | null  {

    const toret = this.datePipe.transform(dateFromAPI, 'yyyy-MM-dd');

    return this.doTransformSimpleDateToNgbDateStruct(toret)
  }

  private doTransformSimpleDateToNgbDateStruct(value: any): NgbDateStruct | null {

    if (!value)
      return null

    let parts = value.split('-');
    const toret = { year: +parts[0], month: +parts[1], day: +parts[2] };
    return toret;
  }


  /**
   * Needed to send correct date value to API when raw data comes from Datepicker
   * @param ngbDate
   * @returns
   */
  tranformNgbDateToISODate(ngbDate: NgbDateStruct | null) {

    if (!ngbDate)
      return;

    try {
      const date = new Date(+ngbDate.year, +ngbDate.month - 1, +ngbDate.day)
      return date.toISOString();

    } catch (error) {
      console.log('Error transformando la fecha: ', error);
      return null;
    }

  }

  /**
   * Format hours and minutes as a string representing the hour
   * @param hour
   * @param minute
   * @returns
   */
  formatTime(hour: number, minute: number): string {

    const hourString = String(hour).padStart(2, '0'); // Asegurar que la hora tenga 2 dígitos
    const minuteString = String(minute).padStart(2, '0'); // Asegurar que los minutos tengan 2 dígitos

    return `${hourString}:${minuteString}`;
  }



  /**
   * Gets key from an enum when the value is proviedes
   * @param enumObject
   * @param value
   * @returns
   */
  getKeyByValue(enumObject: any, value: number): string | undefined {

    const item: any = Object.values(enumObject).find( valItem => valItem = value );

    return item.name || undefined;

  }



  /**
   * Function for DEBUGING that show errors
   * @param formGroup
   */
  logFormErrors(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach((controlName) => {
      const control: any = formGroup.get(controlName) as FormControl;

      if (control instanceof FormControl) {
        if (control.errors) {
          console.log(`Control with error: ${controlName}`);
          console.log(`Errors: ${control.errors}`);
        }
      } else if (control instanceof FormGroup) {
        // Si el control es un grupo anidado, llama a esta función recursivamente
        this.logFormErrors(control);
      }
    });
  }
}
